@import '../public/fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    @media (min-width: 1024px) {
        .page-main .page-container {
            grid-template-columns: 1fr 30%;
        }
    }

    .page-title {
        @apply font-bold my-0 tracking-wide leading-8 text-blue-dark text-3xl lg:text-4xl;
    }

    .pill-button {
        @apply h-10 bg-blue-main text-white px-4 rounded-full font-medium tracking-wide hover:bg-blue-light focus:bg-blue-light inline-hover;
    }

    .page-container {
        @apply flex flex-col mx-auto relative w-11/12;
    }

    .inline-hover {
        @apply transition duration-200 ease-in;
    }

    .inline-hover:hover,
    .inline-hover:active,
    .inline-hover:focus {
        @apply ease-in-out;
    }

    .page-content p {
        @apply text-lg mb-8 leading-7;
    }

    .newsletter-wrapper {
        @apply mb-10 md:mb-20 flex;
    }

    .homepage-newsletter {
        background: linear-gradient(to bottom, #f6f9fc, #f6f9fc 50%, #fff 50%, #fff 100%);
    }

    .videos-list {
        @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-14;
    }

    .videos-list li:first-of-type {
        @apply lg:hidden;
    }

    .newsletter-form {
        background: linear-gradient(to bottom, #daead9, #c4d9c3 100%);
    }
}
